import { commonService } from '.';

function login(email = null, token = null, pwd = null) {
  const body = {
      "LoginFromEmail": email,
      "IDToken": token,
      "Password": pwd
  };
  return commonService.post('Login/authentication', body);
}

function reset(email = null, token = null, pwd = null) {
  const body = {
      "LoginFromEmail": email,
      "IDToken": token,
      "Password": pwd
  };
  return commonService.post('Login/reset', body);
}

function savePassword(email = null, token = null, pwd = null) {
  const body = {
      "LoginFromEmail": email,
      "IDToken": token,
      "Password": pwd
  };
  return commonService.post('Login/savePassword', body);
}

function checkLink(email = null, token = null, pwd = null) {
  const body = {
      "LoginFromEmail": email,
      "IDToken": token,
      "Password": pwd
  };
  return commonService.post('Login/checkLink', body);
}

function changeRole(idUser) {
  return commonService.post('Login/changeRole?idUser=' + idUser);
}

export const authService = {
    login,
    reset,
    savePassword,
    checkLink,
    changeRole
};